import "jspdf-autotable";

export default {
  name: "TDenominationUser",
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      form: {
        userId: "",
        userCode: "",
        officeName: "",
        userName: "",
        date: "",
        balanceNominal: 0,
      },
      utils: {
        data: {
          positionCashTeller: null,
        },
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      table: {
        data: {
          denominationPaper: [],
          denominationCoin: [],
          positionCashTeller: null,
        },
      },
    };
  },
  computed: {
    calculateValueDominationPaper() {
      if (this.table.data.denominationPaper.length > 0) {
        var total = 0;
        this.table.data.denominationPaper.map((index) => {
          total = total + index.denominationValue * index.denominationAmount;
        });
        return total;
      } else {
        return 0;
      }
    },
    calculateValueDominationCoin() {
      if (this.table.data.denominationCoin.length > 0) {
        var total = 0;
        this.table.data.denominationCoin.map((index) => {
          total = total + index.denominationValue * index.denominationAmount;
        });
        return total;
      } else {
        return 0;
      }
    },
    calculateCashOnHand() {
      var total = 0;
      if (this.table.data.denominationPaper.length > 0) {
        this.table.data.denominationPaper.map((index) => {
          total = total + index.denominationValue * index.denominationAmount;
        });
      }
      if (this.table.data.denominationCoin.length > 0) {
        this.table.data.denominationCoin.map((index) => {
          total = total + index.denominationValue * index.denominationAmount;
        });
      }
      return total;
    },
  },
  methods: {
    async printDenominationUser() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        const confirm = await this.simpleConfirmation(
          "Print Denominasi ?",
          "is-info"
        );
        if (!confirm) return;
      } else {
        const confirm = await this.simpleConfirmation(
          "Print Denominasi ?",
          "is-success"
        );
        if (!confirm) return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "report",
          reqUrl: "report/download-denominasi-teller",
        });
        console.log(resp);
        if (resp.data.code) {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
          return;
        }
        const response = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/download-denominasi-teller",
        });

        const blob = new Blob([response.data]);
        const objectUrl = URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = objectUrl;
        fileLink.setAttribute("download", "denomination_teller.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        this.pdfsrc = null;
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
        }, 1000);
      }
    },
    async addDenominationUserBulk() {
      const denominationUserList = this.table.data.denominationPaper.concat(
        this.table.data.denominationCoin
      );
      const payload = {
        denominationDate: this.form.date,
        denominationUserList: denominationUserList,
      };
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Simpan Denominasi ?",
          "is-info"
        );
        if (!confirm) return;
        try {
          this.property.animation.submitLoading = true;
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "denomination-user/bulk",
            payload: payload,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.property.animation.submitLoading = false;
        }
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Simpan Denominasi ?",
          "is-success"
        );
        if (!confirm) return;
        try {
          this.property.animation.submitLoading = true;
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "denomination-user/bulk",
            payload: payload,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.property.animation.submitLoading = false;
        }
      }
    },
    helperGetUserDataFromSession() {
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.form.officeName = getDataFromSession.officeName;
      this.form.userCode = getDataFromSession.userCode;
      this.form.userName = getDataFromSession.userName;
    },
    helperGetUserDataForFindUserId() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          Promise.all(
            resp.data.data.content.map((i) => {
              if (i.userCode === this.form.userCode) {
                this.form.userId = i.userId;
              }
            })
          ).then(() => {
            this.getDenominationUserListByUserId();
          });
        });
    },
    async getReferenceDenomination() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "reference/denomination",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((objectData) => {
            const denominationValue = objectData.denominationValue;
            const denominationType = objectData.denominationType;
            const denominationId = objectData.denominationId;
            const denominationAmount = 0;
            if (objectData.denominationType === "Kertas") {
              this.table.data.denominationPaper.push({
                denominationValue,
                denominationType,
                denominationId,
                denominationAmount,
              });
            } else if (objectData.denominationType === "Logam") {
              this.table.data.denominationCoin.push({
                denominationValue,
                denominationType,
                denominationId,
                denominationAmount,
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDenominationUserListByUserId() {
      try {
        const payload = {
          userId: this.form.userId,
        };
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "denomination-user/denomination-detail",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          Promise.all(
            resp.data.data.detail.map((dataResponse) => {
              if (dataResponse.rdenomination.denominationType === "Kertas") {
                this.table.data.denominationPaper.map(
                  (dataDenominationPaper) => {
                    if (
                      dataDenominationPaper.denominationId ===
                      dataResponse.rdenomination.denominationId
                    ) {
                      dataDenominationPaper.denominationAmount =
                        dataResponse.denominationAmount;
                    }
                  }
                );
              }
              if (dataResponse.rdenomination.denominationType === "Logam") {
                this.table.data.denominationCoin.map((dataDenominationCoin) => {
                  if (
                    dataDenominationCoin.denominationId ===
                    dataResponse.rdenomination.denominationId
                  ) {
                    dataDenominationCoin.denominationAmount =
                      dataResponse.denominationAmount;
                  }
                });
              }
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    chargingDefeaultDataPositionCashTeller() {},
    appendDefaultInputValue() {
      this.form.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    async getBalanceCashTellerDenomination() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "balance-cash/teller-denomination",
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.table.data.positionCashTeller = resp.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserBalanceNominal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "balance-cash/current-balance-nominal",
        });
        if (resp.data.code === "SUCCESS") {
          this.form.balanceNominal = resp.data.data;
        }
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.appendDefaultInputValue();
    this.helperGetUserDataFromSession();
    this.helperGetUserDataForFindUserId();
    this.getReferenceDenomination();
    this.getBalanceCashTellerDenomination();
    this.getUserBalanceNominal();
  },
};
